.Toastify {
  &__toast {
    width: fit-content;
    margin-left: auto;
    box-shadow: 0 0 20px 0 var(--black-two33);
    border: solid 3px;
    border-radius: 10px;
    padding: 15px 15px;
    min-height: 78px;
    background-color: var(--white);

    &-container {
      width: auto;
      min-width: 450px;
      z-index: 2147483641;
    }

    &--error {
      border-color: var(--danger);
    }

    &--success {
      border-color: var(--success);
    }

    &--info {
      border-color: var(--info);
    }

    &--warning {
      border-color: var(--warning);
    }

    &-body {
      margin-right: 10px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 100%;
      display: flex;
      align-items: center;
    }

    &-content {
      display: flex;

      > img {
        margin: auto 0;
      }
    }

    &-text-wrapper {
      color: var(--black-two);
      margin-left: 10px;
      border-left: 1px solid var(--ice-blue);
      padding: 0 10px;
      font-size: 16px;
      line-height: 1.34;
    }

    &-icon {
      margin-right: 10px;
    }

    &-text {
      flex-grow: 1;
      padding-top: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__close-button {
    margin: -5px;

    svg {
      height: 20px;
      width: 20px;

      path {
        fill: var(--black-two);
      }
    }
  }
}
