/* Base styles */
* {
  font-family: 'Gotham', Arial, Helvetica, sans-serif;
  box-sizing: border-box !important;

  &:after,
  &:before {
    box-sizing: border-box !important;
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    padding: 2px;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    width: 8px;
    height: 8px;
    transition: opacity 0.2s;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    border: 2px solid transparent;
    background: transparent;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

div::-webkit-scrollbar {
  width: 10px;
}

div::-webkit-scrollbar * {
  background: transparent;
}

div::-webkit-scrollbar-thumb {
  // background: #4eb3d4 !important;

  &:hover {
    div::-webkit-scrollbar-thumb {
      // background: #4eb3d4 !important;
    }
  }
}

div::-webkit-scrollbar-corner {
  background-color: transparent;
}

body {
  width: 100vw;
  min-width: 320px;
  font-size: 14px !important;
  font-weight: 400;
  color: black;
  overflow: hidden;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  background-color: var(--medium-dark);

  &.modal-open {
    overflow: hidden;
  }
}

#root {
  overflow: hidden;
}

svg {
  pointer-events: none;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
  outline: none;

  &:hover,
  &:link,
  &:active,
  &:visited {
    outline: none !important;
  }

  &:hover {
    text-decoration: none;
  }
}

input,
textarea,
select {
  outline: none !important;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='text'] {
  -moz-appearance: textfield;

  &::-ms-clear {
    display: none;
  }
}

input[type='password'] {
  &::-ms-reveal {
    display: none;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']:hover,
input[type='number']:focus {
  -moz-appearance: textfield;
}

[type='checkbox'],
[type='radio'] {
  color: var(--light-blue-grey);
}
[type='checkbox']:checked,
[type='radio']:checked {
  color: var(--sky);
}

@mixin shadowDrop(
  $outerOffsetX: 0px,
  $outerOffsetY: 1px,
  $outerRadius: 2px,
  $outerColor: --black-two20,
  $spreadDistance: 0
) {
  -moz-box-shadow: $outerOffsetX $outerOffsetY $outerRadius $spreadDistance $outerColor;
  -webkit-box-shadow: $outerOffsetX $outerOffsetY $outerRadius $spreadDistance $outerColor;
  box-shadow: $outerOffsetX $outerOffsetY $outerRadius $spreadDistance $outerColor;
}

@mixin defaultDropShadow() {
  @include shadowDrop(0, 0, 14px, rgba(0, 0, 0, 0.1), 0);
}

button,
input[type='submit'] {
  display: block;
  background: transparent;
  border-radius: 0;
  transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease;
  cursor: pointer;

  &:focus,
  &:active {
    @include defaultDropShadow();
    outline: none !important;
  }
}

.popover {
  z-index: 999999;
  max-width: fit-content !important;
  margin-top: -1px !important;
}
.react-tiny-popover-container {
  z-index: 999999 !important;
}
svg[aria-label='React Hook Form Logo'] {
  pointer-events: all;
}

button[title='Show dev panel'] {
  transform: translate(-18px, 60px);
}

button[title='Close dev panel'] {
  transform: translate(-90px, 0px);
  background-color: rgba(255, 255, 255, 0.2) !important;
  display: flex;
  align-items: center;
}

.react-pdf__Document {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.pdf-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: calc(100vh - 150px);
  width: 100%;

  & > canvas {
    max-width: 100%;
    height: auto !important;
  }
}
.react-pdf__Page__annotations {
  position: absolute;
  top: 40px;
  bottom: 0;
  left: 70px;
  right: 0;

  a:hover {
    opacity: 0.1;
    background: var(--sky);
    box-shadow: 0 2px 10px var(--sky);
  }
}

@media screen and (max-height: 700px) {
  .pdf-page {
    align-items: flex-start;
  }
}

// Modal animations
@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes modalFadeDown {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes modalFadeUp {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }

  100% {
    transform: translateY(-50%);
    opacity: 0;
  }
}

// End modal animations

// Blink animation for loading process
@keyframes blink {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

:root {
  --scrollbar: #848687;
  --dark-blue-grey: #173042;
  --dark-blue-grey50: rgba(23, 48, 66, 0.5);
  --ice-blue: #edf0f2;
  --robin-s-egg-blue: #9ee7fb;
  --pine-green: #081a27;
  --pine-green-two: #081b25;
  --seafoam-blue: #5be5be;
  --very-light-blue: #dfeef2;
  --black: #373636;
  --black33: rgba(55, 54, 54, 0.33);
  --black-two: #000000;
  --black-two15: rgba(0, 0, 0, 0.15);
  --black-three: #333333;
  --ice-blue-two: #e8f1f3;
  --pale-grey: #f4f7f9;
  --light-blue-grey: #d1d6d9;
  --light-blue-grey-two: #dfeef2;
  --light-blue-grey-three: #d1dadc;
  --danger: #f16153;
  --danger2: #eb6383;
  --danger3: #e86c60;
  --success: #07bc0c;
  --info: #3498db;
  --warning: #f1c40f;
  --white: #fff;
  --white10: rgba(255, 255, 255, 0.1);
  --white20: rgba(255, 255, 255, 0.2);
  --white30: rgba(255, 255, 255, 0.3);
  --white40: rgba(255, 255, 255, 0.4);
  --white50: rgba(255, 255, 255, 0.5);
  --white80: rgba(255, 255, 255, 0.8);
  --grey: #a2acb3;
  --soft-grey: #c4c4c4;
  --light-grey: #f0f0f0;
  --sky: #4eb3d4;
  --sky80: rgba(78, 179, 212, 0.8);
  --dark-grey: #838383;
  --dark-grey-two: #2d353b;
  --dark-grey-three: #222222;
  --aquamarine: #4ed4b2;
  --aquamarine15: rgba(78, 212, 178, 0.15);
  --shadow: #c2ccd6;
  --notif-yellow: #f7d77f;
  --yellow: #e1db5f;
  --yellow-two: #fed74b;
  --dark-gray10: rgba(23, 48, 66, 0.1);
  --light-gray75: rgba(237, 247, 251, 0.75);
  --ice-blue75: rgba(158, 231, 251, 0.75);
  --black-two33: rgba(0, 0, 0, 0.33);
  --black-two20: rgba(0, 0, 0, 0.2);
  --dark-blue70: rgba(8, 26, 39, 0.7);
  --dark-gray50: rgba(55, 54, 54, 0.5);
  --medium-dark: #18181b;
  --jaguar: #27272a;
  --builder-bg: #eef2f4;
  --medium-pink: #eb6383;
  --white-smoke: #f8f8f8;
  --bright-gray: #eaeaea;
  --slate-grey: #626e76;
  --lightroom-gray: #e8eaec;
  --dark-blue: #112330;
  --dark-blue-two: #162839;
  --dodge-blue: #2684ff;
  --dodge-blue-two: #2d98d4;
  --dodge-blue-two-hover: rgba(45, 152, 212, 0.98);
  --dodge-blue-two-active: rgba(45, 152, 212, 0.9);
  --solitude: #deebff;
  --aqua-haze: #f4f7f9;
  --divider: #e5e5e5;
  --crystal: #edf7fb;
}
