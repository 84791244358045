@font-face {
  font-family: 'Gotham';
  src: url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Book.eot');
  src: url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
    url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Book.woff2') format('woff2'),
    url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Book.woff') format('woff'),
    url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Bold.eot');
  src: url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
    url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Bold.woff2') format('woff2'),
    url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Bold.woff') format('woff'),
    url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'font-awesome';
  src: url('https://cdn.cyberescape.livingsecurity.com/fonts/fa/fa-brands-400.ttf');
  src: url('https://cdn.cyberescape.livingsecurity.com/fonts/fa/fa-duotone-900.ttf');
  src: url('https://cdn.cyberescape.livingsecurity.com/fonts/fa/fa-light-300.ttf');
  src: url('https://cdn.cyberescape.livingsecurity.com/fonts/fa/fa-regular-400.ttf');
  src: url('https://cdn.cyberescape.livingsecurity.com/fonts/fa/fa-solid-900.ttf');
}
