.date-picker-wrapper {
  input {
    border: none;
  }

  .react-datepicker__day--in-range:not(.react-datepicker__day--range-start):not(.react-datepicker__day--selected) {
    background: var(--light-grey);
    color: var(--black-two);
  }
}
